import { defineStore } from "pinia";
import type { Database } from "~/types/database.types";

type Image = Database["public"]["Tables"]["images"]["Row"];
type Tag = Database["public"]["Tables"]["tags"]["Row"];
type Product = Database["public"]["Tables"]["products"]["Row"] & {
  images: Image[];
  variants: ProductVariant[];
  tags: Tag[];
};
type ProductVariant =
  Database["public"]["Tables"]["product_variants"]["Row"] & {
    images: Image[];
  };

export const useProductStore = defineStore("product", () => {
  const products = ref<Product[]>([]);
  const selectedTag = ref("All");

  const tags = computed(() => [
    "All",
    ...new Set(
      products.value
        ?.map((product) => {
          return product.tags?.map((tag) => tag.title);
        })
        .reduce((acc, val) => acc.concat(val), []) as string[],
    ),
  ]);
  const filteredProducts = computed(() => {
    return products.value.filter((product) =>
      selectedTag.value === "All"
        ? true
        : typeof product.tags?.find(
            (tag) => tag.title === selectedTag.value,
          ) !== "undefined",
    );
  });
  const getProductById = computed(() => {
    return (id: string) =>
      products.value.find((product) => product.slug === id);
  });

  async function getProducts() {
    const { data, error } = await useSupabaseClient<Database>()
      .from("products")
      .select("*, tags(*), images(*), variants:product_variants(*, images(*))")
      .order("order", { nullsFirst: true, ascending: true });

    if (error) {
      throw error;
    } else {
      products.value = data;
    }

    return data;
  }

  function $reset() {
    return getProducts();
  }

  return {
    products,
    tags,
    filteredProducts,
    getProductById,
    getProducts,
    selectedTag,
    $reset,
  };
});
